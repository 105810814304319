import { Roles } from '../constants/Roles';

export interface IRole {
  key: Roles;
  value: string;
  level: number;
}

export const translateUserRoleInRole: Record<string, IRole> = {};
translateUserRoleInRole[Roles.ADMIN] = {
  key: Roles.ADMIN,
  value: 'Admin',
  level: 5,
};
translateUserRoleInRole[Roles.MANAGER] = {
  key: Roles.MANAGER,
  value: 'Manager',
  level: 4,
};
translateUserRoleInRole[Roles.STAFF] = {
  key: Roles.STAFF,
  value: 'Staff',
  level: 3,
};
translateUserRoleInRole[Roles.CREW] = {
  key: Roles.CREW,
  value: 'Crew',
  level: 2,
};
translateUserRoleInRole[Roles.VIEWER] = {
  key: Roles.VIEWER,
  value: 'Viewer',
  level: 1,
};
