import {
  MdDownload,
  MdInsertDriveFile,
  MdKeyboardArrowDown,
  MdModeEdit,
  MdOutlineDeleteOutline,
  MdOutlineMoreHoriz,
  MdSearch,
  MdVisibility,
  MdVisibilityOff,
} from 'react-icons/md';
import styled from 'styled-components';

import {
  BaseDropdownItem,
  BaseLoading,
  Button,
  CheckBoxGroup,
} from '@gbs-monorepo-packages/common';
import { colors, radii } from '@gbs-monorepo-packages/styles';

import { AnalyticsEntryCard } from './components/AnalyticsEntryCard';

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${colors.lightGray50};
`;

export const ArrowDown = styled(MdKeyboardArrowDown)`
  height: 1rem;
  width: 1rem;
`;

export const ButtonContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const UploadButtonContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
`;

export const Header = styled.header`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const IconContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 0.25rem;
`;

export const MainContent = styled.div`
  background-color: ${colors.white300};
  border-radius: ${radii.lg};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
`;

export const TitlePage = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  @media (max-width: 390px) {
    font-size: 1.8rem;
  }
  @media (max-width: 337px) {
    font-size: 1.5rem;
  }
  @media (max-width: 279px) {
    font-size: 1.2rem;
  }
`;

export const SearchInputContainer = styled.div`
  width: 100%;
  display: flex;
  background-color: ${colors.white300};
  height: 2.5rem;
  border: 1px ${colors.lightGray200} solid;

  &:hover {
    border: 1px ${colors.gray300} solid;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 2.2rem;
  background-color: inherit;
  border: 0;
  border-radius: 0 ${radii.sm} ${radii.sm} 0;
  padding-left: 0.5rem;

  &:focus {
    outline: none;
  }
`;

export const SearchIconContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: inherit;
  border-radius: ${radii.sm} 0 0 ${radii.sm};
  padding: 0 0.5rem 0 1rem;
  cursor: pointer;
`;

export const SearchIcon = styled(MdSearch)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const AnalyticssList = styled.ul`
  width: 100%;
`;

export const AnalyticsTypeIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AnalyticsTypeIcon = styled(MdInsertDriveFile)`
  height: 2rem;
  width: 2rem;
`;

export const AnalyticsDeleteIconContainer = styled.button`
  all: unset;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${radii.full};

  &:hover {
    background-color: ${colors.white300};
  }
`;

export const AnalyticsDeleteIcon = styled(MdOutlineDeleteOutline)`
  height: 1.5rem;
  width: 1.5rem;
  color: ${colors.gray300};
`;

export const AnalyticsDownloadIconContainer = styled.button`
  all: unset;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${radii.full};

  &:hover {
    background-color: ${colors.white300};
  }
`;

export const AnalyticsDownloadIcon = styled(MdDownload)`
  height: 1.5rem;
  width: 1.5rem;
  color: ${colors.gray300};
`;

export const AnalyticsRenameIconContainer = styled.button`
  all: unset;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${radii.full};

  &:hover {
    background-color: ${colors.white300};
  }
`;

export const AnalyticsRenameIcon = styled(MdModeEdit)`
  height: 1.5rem;
  width: 1.5rem;
  color: ${colors.gray300};
`;

export const AnalyticsNameContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.3rem 0 0.3rem;
`;

export const AnalyticsName = styled.p``;

export const RenameInputContainer = styled.div`
  display: flex;
  padding: 0 1rem 2rem;
`;

export const RenameInput = styled.input`
  color: ${colors.text};
  flex-grow: 1;
  font-size: 1rem;
  height: 3.25rem;
  padding: 0 1rem;
  border-radius: ${radii.sm};
  border: 1px ${colors.gray400} solid;

  &:focus {
    outline: none;
  }
`;

export const AnalyticsMoreIconContainer = styled.div`
  width: 10%;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${radii.xl};

  &:hover {
    background-color: ${colors.white300};
  }
`;

export const AnalyticsMoreIcon = styled(MdOutlineMoreHoriz)`
  height: 1.5rem;
  width: 1.5rem;
  color: ${colors.gray300};
`;

export const TitleSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
`;

export const DropdownButtonContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
  width: 2.75rem;
`;

export const DropdownItem = styled(BaseDropdownItem)`
  font-size: 1rem;
  font-weight: 400;

  border-radius: ${radii.sm};
  color: ${colors.labelText};
  cursor: pointer;
  height: 1.5rem;
  line-height: 1.125rem;
  padding-left: 25;
  padding: 5px;
  position: relative;
  user-select: none;

  &[data-highlighted] {
    background-color: ${colors.backgroundHover};
    outline: none;
  }
`;

export const AnalyticsCard = styled.div`
  align-items: center;
  scroll-snap-align: start;
  border-bottom: 1px solid ${colors.gray100};
  border-radius: ${radii.xs};
  color: ${colors.textLight};
  display: flex;
  justify-content: space-between;
  min-height: 4rem;
  padding: 0.5rem 1rem;
  scroll-snap-align: start;
  text-decoration: none;

  &:hover {
    background-color: ${colors.backgroundHover};
  }
`;

export const ChartFooter = styled.div``;

export const ChartButtonsContainer = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const ToggleVisibilityButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ToggleChartsIconOn = styled(MdVisibility)`
  width: 1.25rem;
  height: 1.25rem;
`;

export const ToggleChartsIconOff = styled(MdVisibilityOff)`
  width: 1.25rem;
  height: 1.25rem;
`;

export const ToggleButtonText = styled.p`
  margin-right: 0.5rem;
`;

export const AnalyticsCardCustom = styled(AnalyticsEntryCard)``;

export const ListContainer = styled(CheckBoxGroup)``;

export const ChartContainer = styled.div`
  height: 50%;
  flex-grow: 1;
`;

export const AnalyticsBodyContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
