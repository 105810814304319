import { useCallback, useEffect, useState } from 'react';

import {
  type IUploadFileModalProps,
  UploadFileModal,
  blockMultipleSpaces,
} from '@gbs-monorepo-packages/common';

import { type IUploadDocumentProps } from '../../../../services/documentsFolder';
import { RadioButton } from './components/RadioButton';
import { Fieldset, Input, Label } from './styles';

interface IAddDependentModalProps
  extends Omit<IUploadFileModalProps, 'onAccept' | 'onDecline'> {
  onAccept: (document: IUploadDocumentProps) => Promise<void>;
  onDecline?: () => void;
  loading: boolean;
}

const imageExtensions = [
  '.jpg',
  '.jpeg',
  '.png',
  '.svg',
  '.gif',
  '.bmp',
  '.webp',
];
const videoExtensions = ['.mp4', '.mov', '.wmv', '.mkv', '.webm'];
const audioExtensions = ['.mp3', '.wav'];
const textExtensions = [
  '.csv',
  '.txt',
  '.htm',
  '.html',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.pdf',
];

export const AddDocumentModal = ({
  onAccept,
  onDecline,
  loading,
  ...props
}: IAddDependentModalProps): JSX.Element | null => {
  interface IError {
    name?: string;
    document?: string;
  }
  const [error, setError] = useState<IError>({});
  const [description, setDescription] = useState('');
  const [filesType, setFilesType] = useState('files');
  const [filesExtensions, setFilesExtensions] = useState({});

  const resetForm = useCallback(() => {
    setDescription('');
    setError({});
  }, []);

  useEffect(() => {
    if (filesType === 'files') {
      setFilesExtensions({
        'text/*': textExtensions,
      });
    } else if (filesType === 'images') {
      setFilesExtensions({
        'image/*': imageExtensions,
      });
    } else {
      setFilesExtensions({
        'video/*': videoExtensions,
        'audio/*': audioExtensions,
      });
    }
  }, [filesType]);

  const handleDecline = useCallback(() => {
    resetForm();
    onDecline?.();
  }, [onDecline, resetForm]);

  const handleAccept = useCallback(
    async (uploaded: File[]) => {
      let errors = 0;
      const errorsMessage = {
        name: '',
        document: '',
      };

      if (!description.trim()) {
        errorsMessage.name = 'Document description is required.';
        errors++;
      }

      if (uploaded.length === 0) {
        errorsMessage.document = 'File is required.';
        errors++;
      }

      if (errors > 0) {
        setError(errorsMessage);
        return;
      }

      setError({});
      const dependentAux: IUploadDocumentProps = {
        name: description.trim(),
        folderId: 0,
        document: uploaded[0],
      };

      await onAccept?.(dependentAux);
      handleDecline();
    },
    [description, onAccept, handleDecline]
  );

  return (
    <UploadFileModal
      data-cy="upload-document"
      {...props}
      acceptText="Upload"
      mainText="Upload Document"
      onAccept={handleAccept}
      onDecline={handleDecline}
      dropzoneOptions={{
        ...props.dropzoneOptions,
        accept: filesExtensions,
      }}
      biggerModal
      onOpenChange={resetForm}
      errorMessage={error.document}
      disabled={loading}
    >
      <Fieldset filled={!!description.trim()} isInvalid={!!error.name}>
        <Input
          autoComplete="off"
          data-cy="input-description"
          value={description}
          id="description-input"
          name="description-document"
          onChange={(e) => {
            if (e.target.value.length <= 40) {
              setDescription(e.target.value);
            }
          }}
          onBlur={(e) => {
            setDescription(blockMultipleSpaces(e.target.value));
          }}
          required
          type="text"
          pattern=".*\S.*"
        />
        <Label data-cy="label-description" htmlFor="description-input"></Label>
      </Fieldset>
      <RadioButton
        value={filesType}
        onValue={(e) => {
          setFilesType(e);
        }}
      />
    </UploadFileModal>
  );
};
