import { useCallback, useEffect, useRef, useState } from 'react';

import {
  FormBase,
  FormModal,
  type IBaseModalProps,
  SelectData,
  TemplatesType,
  groupsTypes,
} from '@gbs-monorepo-packages/common';

import LoadingSpinnerAnimated from '../../../../assets/spinner.svg';
import { type ICreateCourseUsingCourseGroupProps } from '../../../../services/courses';
import {
  type ITemplateGroupDTO,
  getTemplateGroupsByCompanyId,
} from '../../../../services/templates';
import Logger from '../../../../utils/logger';
import { Center, Input, Loading, SelectContainer } from './styles';

interface IAddPageModalProps
  extends Partial<Omit<IBaseModalProps, 'children' | 'onOpenChange'>> {
  companyId: number;
  onAccept: (
    course: Pick<
      ICreateCourseUsingCourseGroupProps,
      'templateGroupId' | 'title'
    >
  ) => Promise<void>;
  onDecline: () => void;
  open: boolean;
  waitToOpen?: boolean;
}

interface ITemplateGroupSelectData {
  key: number;
  value: string;
}

const defaultOption = TemplatesType.MY_TEMPLATES;

export const AddCourseWithCoursesTemplateModal = ({
  companyId,
  onAccept,
  onDecline,
  open,
  waitToOpen = false,
  zIndex,
  ...props
}: IAddPageModalProps): JSX.Element | null => {
  const isSelectOpen = useRef(new Set());
  const [templateOptionsId, setTemplateOptionsId] =
    useState<number>(defaultOption);
  const [loadingTemplates, setLoadingTemplates] = useState(true);
  const [pageTitle, setPageTitle] = useState('');
  const [templatesGroups, setTemplatesGroups] = useState<
    ITemplateGroupSelectData[]
  >([]);
  const [courseTemplateId, setCourseTemplateId] = useState('');
  const [loading, setLoading] = useState(false);
  const loadedOnce = useRef(false);

  const requiredFields = pageTitle.trim() && courseTemplateId;

  const handleAddPage = useCallback(async () => {
    setLoading(true);
    await onAccept({ templateGroupId: +courseTemplateId, title: pageTitle });

    loadedOnce.current = false;
    setTemplateOptionsId(defaultOption);
    setPageTitle('');
    setCourseTemplateId('');
    setLoading(false);
  }, [onAccept, pageTitle, courseTemplateId]);

  const handleDeclineAddPage = useCallback(() => {
    if (!isSelectOpen.current.size) {
      loadedOnce.current = false;
      setPageTitle('');
      setCourseTemplateId('');
      onDecline?.();
    }
  }, [onDecline]);

  useEffect(() => {
    loadedOnce.current = false;
  }, [templateOptionsId]);

  useEffect(() => {
    let mount = true;

    const loadTemplatesOnce = async () => {
      loadedOnce.current = true;
      setLoadingTemplates(true);
      try {
        const result = await getTemplateGroupsByCompanyId({
          filter: '',
          limit: 99999,
          page: 1,
          templateOptionsId,
        });
        if (mount) {
          const templatesGroup = result.data.map((group: ITemplateGroupDTO) => {
            return {
              key: group.id,
              value: group.title,
            };
          });

          setTemplatesGroups(templatesGroup);
        }
      } catch (err) {
        loadedOnce.current = false;
        Logger.debug('err: ', err);
      } finally {
        setTimeout(() => {
          setLoadingTemplates(false);
        }, 200);
      }
    };

    if (companyId && !loadedOnce.current) {
      void loadTemplatesOnce();
      Logger.notice('loadTemplatesOnce');
    }

    return () => {
      mount = false;
    };
  }, [templateOptionsId, companyId]);

  const handleChangeTemplateType = useCallback((templateType: number) => {
    setCourseTemplateId('');
    setTemplateOptionsId(templateType);
  }, []);

  const handleOpenChange = useCallback((isOpen: boolean, key: string) => {
    if (isOpen) {
      isSelectOpen.current.add(key);
    } else {
      isSelectOpen.current.delete(key);
    }
  }, []);

  return !open ? null : (
    <FormModal
      dataCy="add-course-page-form-modal"
      {...props}
      open={!waitToOpen}
      acceptText="Create course"
      declineText="Cancel"
      mainText="Create from course template"
      onAccept={() => {
        void handleAddPage();
      }}
      onDecline={handleDeclineAddPage}
      onOpenChange={handleDeclineAddPage}
      zIndex={zIndex}
      disabled={Boolean(!requiredFields) || loading}
      loading={loading}
    >
      <FormBase.Content>
        <FormBase.InputContent
          dataCy="label-Course Name"
          filled={!!pageTitle}
          inputRef="page-title"
          label="Course Name"
        >
          <Input
            autoComplete="off"
            data-cy="input-pageTitle"
            name="page-title"
            id="page-title"
            value={pageTitle}
            onChange={(e) => {
              if (e.target.value.length <= 50) {
                setPageTitle(e.target.value);
              }
            }}
            required
            type="text"
          />
        </FormBase.InputContent>
        <SelectContainer data-cy="container-group-type">
          <FormBase.InputContent
            filled
            inputRef="group-type"
            label="Course Template Type"
            value={templateOptionsId >= 0 ? 'template' : undefined}
            isSelectData
            data-cy="template-type"
          >
            <SelectData
              defaultValue={String(templateOptionsId)}
              data={groupsTypes}
              name="group-type"
              placeholder="Choose a template type"
              onValueChange={(id) => {
                handleChangeTemplateType(+id);
              }}
              onOpenChange={(isOpen) => {
                handleOpenChange(isOpen, 'options');
              }}
              required
              zIndex={10}
            />
          </FormBase.InputContent>
        </SelectContainer>
        {loadingTemplates ? (
          <Center>
            <Loading src={LoadingSpinnerAnimated} />
          </Center>
        ) : templatesGroups.length ? (
          <SelectContainer data-cy="template-container">
            <FormBase.InputContent
              data-cy="template-type"
              filled={!!courseTemplateId}
              inputRef="template-type"
              label="Template Type"
              value={courseTemplateId ? 'type' : undefined}
              isSelectData
            >
              <SelectData
                data={templatesGroups}
                dataCy="button-choose-group"
                placeholder="Choose a course template"
                onValueChange={(id) => {
                  setCourseTemplateId(id);
                }}
                onOpenChange={(isOpen) => {
                  handleOpenChange(isOpen, 'options');
                }}
                required
                zIndex={10}
                name="choose-group"
              />
            </FormBase.InputContent>
          </SelectContainer>
        ) : (
          <p data-cy="text-noGroupTemplates">No course templates found</p>
        )}
      </FormBase.Content>
    </FormModal>
  );
};
