import { type IRole } from '../utils/translateUserRoleInRole';

export enum Roles {
  ADMIN = 'ROLE_ADMIN',
  MANAGER = 'ROLE_MANAGER',
  STAFF = 'ROLE_STAFF',
  CREW = 'ROLE_CREW',
  VIEWER = 'ROLE_VIEWER',
}

export const rolesWithLevel: IRole[] = [
  { key: Roles.ADMIN, value: 'Admin', level: 5 },
  { key: Roles.MANAGER, value: 'Manager', level: 4 },
  { key: Roles.STAFF, value: 'Staff', level: 3 },
  { key: Roles.CREW, value: 'Crew', level: 2 },
  { key: Roles.VIEWER, value: 'Viewer', level: 1 },
];
