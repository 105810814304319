import { useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { Roles } from '../../constants/Roles';
import { COURSES, DASHBOARD, HOME, LOGIN } from '../../constants/RoutePaths';
import { useCan } from '../../hooks/useCan';
import { useCompanies } from '../../hooks/useCompanies';
import { getCompanies as getClients } from '../../services/companies';
import { getRouteFrom } from '../../utils/getRoutes';
import { Container, Loading } from './styles';

const getFirstClient = async () =>
  await getClients({
    filter: '',
    limit: 1,
    page: 1,
  })
    .then((response) => response.data.at(0) ?? null)
    .catch((error) => {
      throw error;
    });

export const Redirect = (): JSX.Element => {
  const navigate = useNavigate();
  const userCanSeeDashboard = useCan({ roles: [Roles.ADMIN, Roles.MANAGER] });
  const { selectCompany: selectClient } = useCompanies();

  useEffect(() => {
    const checkWhereToGo = () => {
      switch (userCanSeeDashboard) {
        case true:
          navigate(getRouteFrom(DASHBOARD));
          break;

        case null:
          navigate(getRouteFrom(HOME));
          break;

        default:
          void getFirstClient()
            .then((client) => {
              if (client) {
                selectClient(client);
                navigate(
                  generatePath(getRouteFrom(COURSES), {
                    companyId: client.id,
                  })
                );
                return;
              }
              navigate(getRouteFrom(LOGIN));
            })
            .catch(() => {
              navigate(getRouteFrom(LOGIN));
            });
          break;
      }
    };

    checkWhereToGo();
  }, []);

  return (
    <Container data-cy="loading-container">
      <Loading />
    </Container>
  );
};
