import { produce } from 'immer';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Can, useAuth } from '@gbs-monorepo-packages/auth';
import {
  BaseDropdown,
  BaseModal,
  Button,
  SearchBar,
  SelectData,
  useToast,
} from '@gbs-monorepo-packages/common';

import LoadingSpinnerAnimated from '../../assets/spinner.svg';
import { CenteredText } from '../../components/CenteredText';
import { ContentPagination } from '../../components/ContentPagination';
import { DialogModal } from '../../components/DialogModal';
import { COMPANY_ID } from '../../constants/Env';
import { Roles, rolesWithLevel } from '../../constants/Roles';
import {
  type IApiThrowsError,
  type IPaginationMetaProps,
} from '../../services/api';
import {
  type IPaginationUserMembershipDTO,
  type IUserMembershipDto,
  deleteMember,
  getUsersByMembership,
  inviteUser,
  updateMember,
} from '../../services/users';
import Logger from '../../utils/logger';
import {
  type IRole,
  translateUserRoleInRole,
} from '../../utils/translateUserRoleInRole';
import {
  type UsersCreateSchema,
  type UsersUpdateSchema,
} from '../MembersCompany/userSchema';
import { ModalAddUsers } from './components/ModalAddUsers';
import { ModalUpdateUser } from './components/ModalUpdateUsers';
import {
  ButtonContainer,
  Center,
  ContainerGrid,
  DropdownButtonContainer,
  DropdownItem,
  Header,
  HeaderGrid,
  ItemGrid,
  ItemGridCenter,
  ItemText,
  Loading,
  LoadingContainer,
  MainContainer,
  MainContent,
  TitlePage,
} from './styles';

export interface ISelectClientsProps {
  onValueChange?: (value: string) => void;
}

interface IUserMembershipWithCurrentRoles extends IUserMembershipDto {
  currentRole?: IRole | null;
}

const AlmostRoles = [Roles.ADMIN, Roles.MANAGER, Roles.STAFF, Roles.CREW];

const getCurrentRole = (userRoles: string[]): IRole | null => {
  let currentRole = null;
  for (const value of userRoles.values()) {
    const userRoleAux = translateUserRoleInRole[value] ?? null;
    // const userRoleAux = roles.find(({ key }) => key === value);
    // userRoleAux can be null
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!userRoleAux) {
      continue;
    }

    if (!currentRole) {
      currentRole = userRoleAux;
    } else if (currentRole.level < userRoleAux.level) {
      currentRole = userRoleAux;
    }
  }

  return currentRole;
};

export const Members = (): JSX.Element => {
  const isSelectOpen = useRef(new Set());

  const { user } = useAuth();
  const [loadingMembers, setLoadingMembers] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [loadingInvite, setLoadingInvite] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [successRequest, setSuccessRequest] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogLoadingOpen, setDialogLoadingOpen] = useState(false);
  const [dialogEditOpen, setDialogEditOpen] = useState(false);

  const [users, setUsers] = useState<IUserMembershipWithCurrentRoles[]>([]);
  const [userToDelete, setUserToDelete] =
    useState<IUserMembershipWithCurrentRoles | null>(null);

  const [firstNameEdit, setFirstNameEdit] = useState('');
  const [lastNameEdit, setLastNameEdit] = useState('');
  const [emailEdit, setEmailEdit] = useState('');
  const [managerTypeEdit, setManagerTypeEdit] = useState('');
  const [phoneEdit, setPhoneEdit] = useState('');
  const [roleEdit, setRoleEdit] = useState('');

  const { addToast } = useToast();

  const [search, setSearch] = useState('');
  const lastSearch = useRef(search);
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);

  const isModalDeleteOpen = !!userToDelete;

  const currentRole = useMemo(() => {
    const userRoles = user?.roles ?? [];
    if (!userRoles.length) {
      return null;
    }

    return getCurrentRole(userRoles);
  }, [user]);

  const rolesAux = useMemo(() => {
    if (currentRole !== null) {
      return rolesWithLevel.filter(
        ({ level }) => level <= (currentRole?.level ?? 0)
      );
    } else {
      return [];
    }
  }, [currentRole]);

  useEffect(() => {
    if (successRequest) {
      setTimeout(() => {
        setSuccessRequest(false);
      }, 2000);
    }
  }, [successRequest]);

  const getUsers = useCallback(
    (page: number, limit: number) => {
      setLoadingMembers(true);
      getUsersByMembership(page, limit, search ?? '')
        .then((response: IPaginationUserMembershipDTO) => {
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].currentRole = getCurrentRole(
              response.data[i].roles
            );
          }
          setUsers(response.data);
          setPaginationMeta(response.meta);

          if (dialogOpen) {
            resetForm();
            addToast({
              title: 'Success',
              description: 'User has been added!',
              styleType: 'success',
              dataCy: 'success-toast',
            });
          }

          if (isModalDeleteOpen) {
            setLoadingDelete(false);
            setUserToDelete(null);
            addToast({
              title: 'Success',
              description: 'User deleted!',
              styleType: 'success',
              dataCy: 'success-toast',
            });
          }
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
        })
        .finally(() => {
          setLoadingMembers(false);
        });
    },
    [addToast, dialogOpen, isModalDeleteOpen, search]
  );

  const handleDeclineDelete = useCallback(() => {
    setUserToDelete(null);
  }, []);

  const handleAcceptDelete = useCallback(() => {
    if (userToDelete && isModalDeleteOpen) {
      setLoadingDelete(true);
      void deleteMember(userToDelete.id)
        .then(() => {
          getUsers(1, 15);
          setSuccessRequest(true);
          setDialogOpen(false);
        })
        .catch(() => {
          setTimeout(() => {
            setLoadingDelete(false);
            setUserToDelete(null);
            addToast({
              title: 'Something went wrong',
              description: 'An error occurred while deleting the user',
              styleType: 'error',
              dataCy: 'error-toast',
            });
          }, 2000);
        });
    }
  }, [userToDelete, isModalDeleteOpen, getUsers, addToast]);

  const handleRoleSelectChange = (
    value: string,
    email: string,
    firstName: string,
    lastName: string,
    managerType: string,
    phone: string
  ) => {
    setDialogLoadingOpen(true);

    void updateMember({
      firstName,
      lastName,
      email,
      roles: Array(value),
      managerType,
      phone,
    })
      .then(() => {
        getUsers(1, 15);
        setDialogLoadingOpen(false);
        addToast({
          title: 'Success',
          description: 'User updated!',
          styleType: 'success',
          dataCy: 'success-toast',
        });
      })

      .catch((error: IApiThrowsError) => {
        setTimeout(() => {
          setLoadingInvite(false);
          let errorMessage = '';
          if (error.response && error.response.status >= 500) {
            errorMessage =
              'An error occurred. Please try again or contact Benefit Education support.';
          } else {
            errorMessage = error.response?.data.error.message ?? '';
          }
          addToast({
            title: 'Something went wrong',
            description: errorMessage,
            styleType: 'error',
            dataCy: 'error-toast',
          });
        }, 2000);
      });
  };

  const inviteTeammateSubmit = (data: UsersCreateSchema) => {
    setLoadingInvite(true);
    void inviteUser({
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      roles: [data.roles],
      clientId: Number(data.clientId),
      managerType: data.managerType,
      phone: data.phone,
    })
      .then(() => {
        getUsers(1, 15);
        setDialogOpen(false);
      })
      .catch((error: IApiThrowsError) => {
        setTimeout(() => {
          let errorMessage = '';
          if (error.response && error.response.status >= 500) {
            errorMessage =
              'An error occurred. Please try again or contact Benefit Education support.';
          } else {
            errorMessage = error.response?.data.error.message ?? '';
          }
          addToast({
            title: 'Something went wrong',
            description: errorMessage,
            styleType: 'error',
            dataCy: 'error-toast',
          });
        }, 2000);
      })
      .finally(() => {
        setLoadingInvite(false);
      });
  };

  const handlerEditSubmit = (data: UsersUpdateSchema) => {
    setLoadingEdit(true);
    void updateMember({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      roles: [],
      managerType: data.managerType ?? '',
      phone: data.phone ?? '',
    })
      .then(() => {
        getUsers(1, 15);
        setDialogEditOpen(false);
        addToast({
          title: 'Success',
          description: 'User updated!',
          styleType: 'success',
          dataCy: 'success-toast',
        });
      })
      .catch((error: IApiThrowsError) => {
        Logger.debug('error: ', error);
        setTimeout(() => {
          setLoadingInvite(false);
          addToast({
            title: 'Something went wrong',
            description: 'An error occurred while updating the user',
            styleType: 'error',
            dataCy: 'error-toast',
          });
        }, 2000);
      })
      .finally(() => {
        setLoadingEdit(false);
      });
  };

  const handleOpenModalEdit = (data: IUserMembershipWithCurrentRoles) => {
    setDialogEditOpen(true);
    setFirstNameEdit(data.firstName);
    setLastNameEdit(data.lastName);
    setEmailEdit(data.email);
    setManagerTypeEdit(data.managerType ?? '');
    setPhoneEdit(data.phone ?? '');
    setRoleEdit(data.currentRole?.key ?? '');
  };

  const handleOpenModalDelete = (data: IUserMembershipWithCurrentRoles) => {
    setUserToDelete(data);
  };

  const resetForm = () => {
    if (!isSelectOpen.current.size) {
      setLoadingInvite(false);
      setDialogOpen(false);
    }
  };

  const searchUsers = (searchByButton = false) => {
    if (!loadingMembers || searchByButton) {
      setLoadingMembers(true);
      const pageAux = searchByButton
        ? 0
        : Number((paginationMeta?.page ?? 0) > 0 ? paginationMeta?.page : 0);
      getUsersByMembership(pageAux + 1, 15, search)
        .then((response: IPaginationUserMembershipDTO) => {
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].currentRole = getCurrentRole(
              response.data[i].roles
            );
          }

          setUsers(
            produce((draft) => {
              draft.push(...response.data);
            })
          );
          setPaginationMeta(response.meta);
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
        })
        .finally(() => {
          setLoadingMembers(false);
        });
    }
  };

  useEffect(() => {
    if (!users.length) {
      getUsers(1, 15);
    }
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (search.trim() !== lastSearch.current) {
      const execSearch = () => {
        lastSearch.current = search.trim();
        setUsers([]);
        searchUsers(true);
      };

      if (search.trim() === '') {
        execSearch();
      } else {
        timer = setTimeout(execSearch, 1000);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [search]);

  return (
    <MainContainer data-cy="page-container">
      <Header data-cy="header-user">
        <TitlePage data-cy="title-pageName">Users</TitlePage>
        <Can data-cy={AlmostRoles} roles={AlmostRoles}>
          <ButtonContainer data-cy="button-container-addTeammate">
            <Button
              dataCy="button-addUser"
              onClick={() => {
                setDialogOpen(true);
              }}
              type="button"
              disabled={loadingInvite || loadingMembers}
            >
              Add User
            </Button>
          </ButtonContainer>
        </Can>
      </Header>

      <MainContent data-cy="list-card">
        <SearchBar
          disabled={loadingMembers}
          search={search}
          placeholder="Search user"
          onChange={(e) => {
            if (e.target.value.length <= 60) {
              setSearch(e.target.value);
            }
          }}
          onClearInput={() => {
            setSearch('');
          }}
          loading={loadingMembers}
        />

        <ContentPagination dataCy="content-members">
          <HeaderGrid data-cy="header-grid">
            <ItemGrid data-cy="item-grid">
              <p data-cy="column-user">User Name</p>
            </ItemGrid>

            <Can data-cy={AlmostRoles} roles={AlmostRoles}>
              <ItemGridCenter data-cy="item-grid">
                <p data-cy="column-client">Client</p>
              </ItemGridCenter>

              <ItemGridCenter data-cy="item-grid">
                <p data-cy="column-managerType">Manager Type</p>
              </ItemGridCenter>

              <ItemGridCenter data-cy="item-grid">
                <p data-cy="column-permission">Permission</p>
              </ItemGridCenter>
            </Can>
          </HeaderGrid>
          {loadingMembers && users.length === 0 ? (
            <LoadingContainer data-cy="loading-members-container">
              <Loading />
            </LoadingContainer>
          ) : users.length > 0 ? (
            <InfiniteScroll
              height={200}
              style={{
                overflow: 'auto',
                maxHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
              }}
              dataLength={users.length}
              next={searchUsers}
              hasMore={users.length < (paginationMeta?.total ?? 0)}
              loader={
                <LoadingContainer data-cy="loading-teammates-container">
                  <Loading />
                </LoadingContainer>
              }
              endMessage={
                <CenteredText
                  message="You have seen it all"
                  dataCy="pagination-endLimit"
                />
              }
            >
              {users.map((member) => (
                <ContainerGrid data-cy={member.id} key={member.id}>
                  <ItemGrid data-cy="item-grid">
                    <ItemText
                      data-cy={`${member.firstName} ${member.lastName}`}
                    >
                      {member.firstName} {member.lastName}
                    </ItemText>
                    <ItemText data-cy={member.email}>{member.email}</ItemText>
                  </ItemGrid>
                  <Can data-cy={AlmostRoles} roles={AlmostRoles}>
                    {(currentRole?.level ?? 0) >=
                      (member.currentRole?.level ?? 0) &&
                    member.id !== user?.id &&
                    (user?.companyId === Number(COMPANY_ID) ||
                      user?.companyId === member.client?.id) ? (
                      <>
                        <ItemGridCenter data-cy="item-grid">
                          <ItemText data-cy={member.client?.name ?? ''}>
                            {member.client?.name ?? ''}
                          </ItemText>
                        </ItemGridCenter>
                        <ItemGridCenter data-cy="item-grid">
                          <ItemText data-cy={member.managerType}>
                            {member.managerType ?? '-'}
                          </ItemText>
                        </ItemGridCenter>
                        {user?.companyId === Number(COMPANY_ID) ||
                        (user?.companyId === member.client?.id &&
                          (member.currentRole?.level ?? 0) < 4) ? (
                          <ItemGridCenter data-cy="item-grid">
                            <SelectData
                              data={
                                member.client?.id === Number(COMPANY_ID)
                                  ? rolesAux
                                  : rolesAux.slice(-3)
                              }
                              dataCy="button-select-role"
                              name="select-role"
                              onValueChange={(e) => {
                                handleRoleSelectChange(
                                  e,
                                  member.email,
                                  member.firstName,
                                  member.lastName,
                                  member.managerType ?? '',
                                  member.phone ?? ''
                                );
                              }}
                              defaultValue={member.roles[0]}
                            />
                          </ItemGridCenter>
                        ) : (
                          <ItemGrid data-cy="item-grid">
                            <ItemText
                              style={{ textAlign: 'center' }}
                              data-cy={member.currentRole?.value}
                            >
                              {member.currentRole?.value}
                            </ItemText>
                          </ItemGrid>
                        )}
                        <ItemGridCenter data-cy="item-grid">
                          <DropdownButtonContainer data-cy="dropdown-container">
                            <BaseDropdown
                              dataCy={`dropdown-menu-button`}
                              onOpenChange={(isOpen) => {
                                setIsDropdownOpen(isOpen);
                              }}
                            >
                              <DropdownItem
                                data-cy={`edit-user`}
                                onClick={() => {
                                  handleOpenModalEdit(member);
                                }}
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                data-cy={`delete-user`}
                                onClick={() => {
                                  handleOpenModalDelete(member);
                                }}
                              >
                                Delete
                              </DropdownItem>
                            </BaseDropdown>
                          </DropdownButtonContainer>
                        </ItemGridCenter>
                      </>
                    ) : (
                      <>
                        <ItemGridCenter data-cy="item-grid">
                          <ItemText data-cy={member.client?.name ?? ''}>
                            {member.client?.name ?? ''}
                          </ItemText>
                        </ItemGridCenter>
                        <ItemGridCenter data-cy="item-grid">
                          <ItemText data-cy={member.managerType}>
                            {member.managerType ?? '-'}
                          </ItemText>
                        </ItemGridCenter>
                        <ItemGrid data-cy="item-grid">
                          <ItemText
                            style={{ textAlign: 'center' }}
                            data-cy={member.currentRole?.value}
                          >
                            {member.currentRole?.value}
                          </ItemText>
                        </ItemGrid>
                      </>
                    )}
                  </Can>
                </ContainerGrid>
              ))}
            </InfiniteScroll>
          ) : (
            <CenteredText
              message="No users found"
              dataCy="text-noTeammates-found"
            />
          )}
        </ContentPagination>
      </MainContent>
      <ModalAddUsers
        onAccept={inviteTeammateSubmit}
        onDecline={() => {
          setDialogOpen(false);
        }}
        open={dialogOpen}
        loading={loadingInvite}
        success={successRequest}
      />

      <ModalUpdateUser
        onAccept={handlerEditSubmit}
        onDecline={() => {
          setDialogEditOpen(false);
        }}
        open={!isDropdownOpen && dialogEditOpen && emailEdit !== ''}
        loading={loadingEdit}
        email={emailEdit}
        firstName={firstNameEdit}
        lastName={lastNameEdit}
        managerType={managerTypeEdit}
        phone={phoneEdit}
        currentRole={roleEdit}
      />

      <BaseModal
        onOpenChange={setDialogLoadingOpen}
        open={dialogLoadingOpen}
        dataCy="spinner-loading-content-modal"
        hiddenCloseButton={true}
      >
        <Center>
          <Loading src={LoadingSpinnerAnimated} />
        </Center>
      </BaseModal>

      <DialogModal
        dataCy="dialogModal-deleteTeammate"
        acceptText="Confirm"
        declineText="Cancel"
        open={!isDropdownOpen && isModalDeleteOpen}
        loading={loadingDelete}
        mainText={`Are you sure you want to delete ${
          userToDelete?.firstName ?? 'this user'
        } ${userToDelete?.lastName ?? ''}?`}
        onAccept={handleAcceptDelete}
        onDecline={handleDeclineDelete}
        onOpenChange={handleDeclineDelete}
      />
    </MainContainer>
  );
};
