import { useCallback, useState } from 'react';

import {
  type IBaseModalProps,
  blockMultipleSpaces,
} from '@gbs-monorepo-packages/common';

import {
  type IDocumentDTO,
  type IRenameDocumentProps,
} from '../../../../services/documentsFolder';
import {
  ContainerWithError,
  ErrorMessage,
  Fieldset,
  FormModalCustom,
  Grid,
  Input,
  Label,
} from './styles';

interface IEditDocumentModalProps
  extends Partial<Omit<IBaseModalProps, 'children' | 'onOpenChange'>> {
  document: IDocumentDTO;
  onAccept: (document: IRenameDocumentProps) => void;
  onDecline: () => void;
  open: boolean;
  loading: boolean;
}

export const EditDocumentModal = ({
  document,
  onAccept,
  onDecline,
  open,
  loading,
  zIndex,
  ...props
}: IEditDocumentModalProps): JSX.Element | null => {
  interface IError {
    description?: string;
  }

  const [error, setError] = useState<IError>({});
  const [description, setDescription] = useState(document.name ?? '');

  const handleEditDependent = useCallback(() => {
    let errors = 0;
    const errorsMessage = {
      description: '',
    };

    if (!description.trim()) {
      errorsMessage.description = 'Description is required.';
      errors++;
    }

    if (errors > 0) {
      setError(errorsMessage);
      return;
    }

    setError({});
    const documentAux: IRenameDocumentProps = {
      documentId: String(document.id),
      description: description.trim(),
    };

    onAccept(documentAux);
  }, [description, document.id, onAccept]);

  const handleDeclineEditDependent = useCallback(() => {
    onDecline?.();
  }, [onDecline]);

  return !open ? null : (
    <FormModalCustom
      loading={loading}
      biggerModal
      dataCy="modal-editDocument"
      {...props}
      open={open}
      acceptText="Update"
      declineText="Cancel"
      mainText="Edit Document"
      onAccept={handleEditDependent}
      onDecline={handleDeclineEditDependent}
      onOpenChange={handleDeclineEditDependent}
      zIndex={zIndex}
    >
      <Grid>
        <ContainerWithError>
          <Fieldset filled={!!description} isInvalid={!!error.description}>
            <Input
              data-cy="input-description"
              value={description}
              autoComplete="off"
              id="description-input"
              name="description"
              onChange={(e) => {
                if (e.target.value.length <= 40) {
                  setDescription(e.target.value);
                }
              }}
              onBlur={(e) => {
                setDescription(blockMultipleSpaces(e.target.value));
              }}
              required
              type="text"
            />
            <Label data-cy="label-description" htmlFor="description-input">
              Description
            </Label>
          </Fieldset>
          <ErrorMessage data-cy={error.description}>
            {error.description}
          </ErrorMessage>
        </ContainerWithError>
      </Grid>
    </FormModalCustom>
  );
};
