import {
  type IPaginationMetaProps,
  type IPaginationResponseData,
  type IResponseData,
} from '@gbs-monorepo-packages/common';

import { api } from './api';
import { type IFolderDTO } from './folders';

const baseApiDocuments = 'api/documents/';

export interface IDocumentDTO {
  name: string;
  id: number;
  folder: {
    id: number;
    name: string;
  };
  cloudStorageKey: string;
  path: string;
  extension: string;
  createdAt: string;
  updatedAt?: string;
}

export interface IResponseDataFolderItems {
  documents: IDocumentDTO[];
  subFolders: IFolderDTO[];
}

export interface IPaginationDocumentDTO {
  data: IResponseDataFolderItems;
  meta: IPaginationMetaProps;
}

export interface IPaginationDocumentAllDTO {
  data: IDocumentDTO[];
  meta: IPaginationMetaProps;
}

export interface IGetListDocumentsByFolderIdProps {
  folderId: string;
  page: number;
  limit: number;
  filter?: string;
  sort?: string;
}

export interface IGetDocumentsByFolderIdProps {
  folderId: string;
}

export interface IUploadDocumentProps {
  document: File;
  name: string;
  folderId: number;
}

export interface IRenameDocumentProps {
  documentId: string;
  description: string;
}

export interface IDeleteDocumentProps {
  documentId: string;
}

export interface IGetDocumentByIdProps {
  documentId: string;
}

export interface IDocumentFailedDTO {
  uploadFileId: number;
  message: string;
  fileName: string;
  documentName: string | null;
}

export const getListDocumentsByClient = async ({
  folderId,
  page,
  limit,
  filter = '',
  sort = '',
}: IGetListDocumentsByFolderIdProps): Promise<IPaginationDocumentDTO> => {
  const result = await api
    .get<IPaginationResponseData<IResponseDataFolderItems>>(
      `${baseApiDocuments}${folderId}/list/pfm?page=${page}&limit=${limit}&filter=${encodeURIComponent(
        filter
      )}&sorted=${sort}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const uploadDocument = async ({
  folderId,
  document,
  name,
}: IUploadDocumentProps): Promise<IDocumentDTO> => {
  const data = new FormData();
  data.append('document', document, document.name);
  data.append('name', name);
  data.append('folderId', folderId.toString());
  const result = await api
    .post<IResponseData<IDocumentDTO>>(`${baseApiDocuments}pfm`, data)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const renameDocument = async ({
  documentId,
  description,
}: IRenameDocumentProps): Promise<IDocumentDTO> => {
  const result = await api
    .patch<IResponseData<IDocumentDTO>>(
      `${baseApiDocuments}${documentId}/pfm`,
      {
        name: description,
      }
    )
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const deleteDocument = async ({
  documentId,
}: IDeleteDocumentProps): Promise<{ message: string }> => {
  const result = await api
    .delete<IResponseData>(`${baseApiDocuments}${documentId}/pfm`)
    .then((resp) => resp.data.data)
    .catch((err) => {
      throw err;
    });

  return result;
};

export const getPrivateDocument = async ({
  documentId,
}: IGetDocumentByIdProps): Promise<{ data: string }> => {
  const result = await api
    .get<{ data: string }>(`${baseApiDocuments}private/${documentId}/`)
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });
  return result;
};

export const getListAllDocumentsByFolder = async ({
  folderId,
  page,
  limit,
  filter = '',
  sort = '',
}: IGetListDocumentsByFolderIdProps): Promise<IPaginationDocumentAllDTO> => {
  const result = await api
    .get<IPaginationResponseData<IDocumentDTO[]>>(
      `${baseApiDocuments}folder/${folderId}/all/documents?page=${page}&limit=${limit}&filter=${encodeURIComponent(
        filter
      )}&sorted=${sort}`
    )
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });

  return result;
};
